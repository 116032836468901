'use strict';

var atrack;

/**
 * Initialize the GDDL analytics tracker
 */
function initAnalyticsTracker() {
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
}

/**
 * Send tile click event to GDDL
 * @param {Object} $el - jQuery element
 * @param {string} event - event name
 * @returns {void}
*/
function sendTileEvent($el, event = 'contentblock-click') {
    const position = $el.closest('.product-grid').find('> [class^="col-"]:visible, > [class*=" col-"]:visible').index($el.closest('.product-grid > *'));
    atrack.trackEvent({
        event: event,
        info: {
            name: $el.data('gddl') || '',
            category: 'plp-content-tile',
            type: 'branded-tile',
            position: position || '',
            placement: 'plp',
            target_url: $el.attr('href') || $el.find('a').attr('href') || $el.closest('a').attr('href') || ''
        }
    });
}

const createComponentPath = ($parents) => {
    let pathList = [];
    $parents.each(function () {
        const obj = $(this).find('.experience-component-analytics').first().data('object');
        const crumb = obj.type + '.' + obj.id;
        pathList.unshift(crumb);
    });

    return pathList.join('/');
};

const createContentObject = ($el, data, isButton) => {
    let object = {};
    const pageId = $('.pd-page-id').attr('id');
    const $parents = $el.parents('.experience-component');

    object.name = data.name || data.id;

    if (isButton) {
        object.text = data.text;
    }

    object.category = data.type || '';
    object.type = data.id || '';
    object.position = $parents.last().index() + 1 || '';
    object.placement = pageId + '/' + createComponentPath($parents);
    object.targeturl = data.url || '';

    return object;
};

const pushObservedContentImpressions = (entries, observer) => {
    entries.forEach(entry => {
        if (entry.intersectionRatio <= 0.75) {
            return;
        }

        const $el = $(entry.target);
        const data = $el.data('object');

        if ($el.data('gddl') || $el.closest('[data-gddl]').length > 0) {
            sendTileEvent($el, 'contentblock-impression');
            observer.unobserve(entry.target);
            return;
        }

        if (data && !data.ignore && data.name) {
            let ecommerceData;

            if (data.button) {
                ecommerceData = {
                    event: 'button-impression',
                    info: createContentObject($el, data, data.button)
                };
            } else {
                ecommerceData = {
                    event: 'contentblock-impression',
                    info: createContentObject($el, data, data.button)
                };
            }

            atrack.trackEvent(ecommerceData);
            observer.unobserve(entry.target);
        }
    });
};

const initComponentImpressions = () => {
    let targets = document.querySelectorAll('.experience-component-analytics,[data-gddl]');
    if ('IntersectionObserver' in window) {
        try {
            const intersectionObserver = new IntersectionObserver(function (entries) {
                pushObservedContentImpressions(entries, intersectionObserver);
            }, {
                threshold: [0.75]
            });

            targets.forEach(target => {
                intersectionObserver.observe(target);
            });
        } catch (error) {
            // console.log('Error initializing IntersectionObserver', error);
        }
    } else {
        targets.forEach(target => {
            sendTileEvent($(target), 'contentblock-impression');
        });
    }
};

const initProductClicks = () => {
    $(document).on('click', '.experience-component', function (e) {
        e.stopImmediatePropagation();

        const $el = $(this).find('.experience-component-analytics').first();
        const data = $el.data('object');

        if (data && !data.ignore && !data.impressionOnly && data.name) {
            let ecommerceData;

            if (data.button) {
                ecommerceData = {
                    event: 'button-click',
                    info: createContentObject($el, data, data.button)
                };
            } else {
                ecommerceData = {
                    event: 'contentblock-click',
                    info: createContentObject($el, data, data.button)
                };
            }

            atrack.trackEvent(ecommerceData);
        }
    });
};

const initTileClicks = () => {
    // data-gddl is used in content assets
    $(document).on('click', '[data-gddl]', function () {
        sendTileEvent($(this), 'contentblock-click');
    });
};

const initPdpContentBlockImpression = () => {
    const $pdpContentBlocks = $('.pdp-content-block a');
    if (!$pdpContentBlocks.length) return;

    const pdpContenBlockObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const $block = $(entry.target);
                const $blockHolder = $block.parents('.js-pdp-content-block-holder');

                atrack.trackEvent({
                    event: 'contentblock-impression',
                    info: {
                        name: $block.data('gddl-name') || '',
                        category: 'pdp-content-blocks',
                        position: $blockHolder.data('gddl-position'),
                        placement: 'pdp',
                        type: 'blog',
                        target_url: $block.attr('href') || ''
                    }
                });

                observer.unobserve(entry.target);
            }
        });
    }, {
        threshold: 0.75
    });

    // Observe each content block
    $pdpContentBlocks.each((i, block) => {
        pdpContenBlockObserver.observe(block);
    });
};

const initPdpContentBlockClicks = () => {
    $(document).on('click', '.pdp-content-block a', (e) => {
        const $this = $(e.currentTarget);
        const $blockHolder = $this.parents('.js-pdp-content-block-holder');

        atrack.trackEvent({
            event: 'contentblock-click',
            info: {
                name: $this.data('gddl-name') || '',
                category: 'pdp-content-blocks',
                position: $blockHolder.data('gddl-position'),
                placement: 'pdp',
                type: 'blog',
                target_url: $this.attr('href') || ''
            }
        });
    });
};

const init = () => {
    initAnalyticsTracker();
    initComponentImpressions();
    initProductClicks();
    initTileClicks();
    initPdpContentBlockImpression();
    initPdpContentBlockClicks();
};

$(() => {
    init();
});
